export const menuItems = [
  {
    label: "menuitems.projects.text",
    icon: "bx-briefcase-alt-2",
    link: "/proyectos/proyectos",
    nombreModulo: "proyectos",
  },
  {
    label: "menuitems.usuarios.text",
    icon: "mdi-account-group ",
    nombreModulo: "usuario",
    subItems: [
      {
        label: "menuitems.usuarios.list.usuario",
        link: "/usuarios/usuario",
        nombreModulo: "usuario",
      },
      {
        label: "menuitems.usuarios.list.rol",
        link: "/usuarios/rol",
        nombreModulo: "rol",
      },
      {
        label: "menuitems.usuarios.list.moduloPermiso",
        link: "/usuarios/moduloPermiso",
        nombreModulo: "moduloPermiso",
      },
      {
        label: "menuitems.usuarios.list.cargo",
        link: "/usuarios/cargo",
        nombreModulo: "cargo",
      },
      {
        label: "menuitems.usuarios.list.usuarioBodegaMovil",
        link: "/usuarios/usuarioBodega",
        nombreModulo: "usuarioBodega",
      },
    ],
  },

  {
    label: "menuitems.mantenedores.text",
    icon: "bx bx-slider-alt",
    nombreModulo: "mantenedores",
    subItems: [
      {
        label: "menuitems.mantenedores.list.actividad",
        link: "/mantenedores/actividad",
        nombreModulo: "actividad",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.actividadTipoProyectoConfiguracion",
        link: "/mantenedores/actividadTipoProyectoConfiguracion",
        nombreModulo: "actividadTipoProyectoConfiguracion",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.tipoDocumento",
        link: "/mantenedores/tipoDocumento",
        nombreModulo: "tipoDocumento",
      },
      {
        label: "menuitems.mantenedores.list.nombreDocumento",
        link: "/mantenedores/nombreDocumento",
        nombreModulo: "nombreDocumento",
      },
      {
        label: "menuitems.mantenedores.list.foldersDocuments",
        link: "/mantenedores/documentos/carpetas",
        nombreModulo: "manteinerFoldersDocuments",
      },
      {
        label: "menuitems.mantenedores.list.tipoProyecto",
        link: "/mantenedores/tipoProyecto",
        nombreModulo: "tipoProyecto",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.tipoCliente",
        link: "/mantenedores/tipoCliente",
        nombreModulo: "tipoCliente",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.tipoVenta",
        link: "/mantenedores/tipoVenta",
        nombreModulo: "tipoVenta",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.tipoInstalacion",
        link: "/mantenedores/tipoInstalacion",
        nombreModulo: "tipoInstalacion",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.etapaProyecto",
        link: "/mantenedores/etapaProyecto",
        nombreModulo: "etapaProyecto",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.subEtapaProyecto",
        link: "/mantenedores/subEtapaProyecto",
        nombreModulo: "subEtapaProyecto",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.normativa",
        link: "/mantenedores/normativa",
        nombreModulo: "normativa",
        active: null
      },
      {
        label: "menuitems.mantenedores.list.appFlexEnergetica",
        link: "/mantenedores/appFlexEnergetica",
        nombreModulo: "appFlexEnergetica",
      },
      {
        label: "menuitems.mantenedores.list.templateGantt",
        link: "/mantenedores/templateGantt",
        nombreModulo: "templateGantt",
      },
      {
        label: "menuitems.mantenedores.list.approvalMatrix",
        link: "/mantenedores/matrizAprobacion",
        nombreModulo: "tipoDocumento",
        passwordRequired: true,
      },
      {
        label: "menuitems.mantenedores.list.employees",
        link: "/mantenedores/trabajadores",
        nombreModulo: "tipoDocumento",
        passwordRequired: true,
      },
      {
        label: "menuitems.mantenedores.list.mailForm",
        link: "/mantenedores/correo/notificacion/formularios",
        nombreModulo: "tipoDocumento",
      },
    ],
  },
  
  {
    label: "menuitems.supplying.text",
    icon: "bx bx-detail",
    nombreModulo: "tutorial",
    subItems: [
      {
        label: "menuitems.materiales.list.solicitudes",
        nombreModulo: "tutorial",
        subItems: [
          {
            label: "menuitems.materiales.list.mis-solicitudes",
            link: "/my-request-materials",
            nombreModulo: "solicitudMaterial",
          },
          {
            label: "menuitems.materiales.list.generar-solicitud",
            nombreModulo: "tutorial",
            subItems: [
              {
                label: "menuitems.materiales.list.solicitudes_sap",
                link: "/sap/request-materials",
                nombreModulo: "solicitudMaterial",
              },
              {
                label: "menuitems.materiales.list.devoluciones",
                link: "/request-materials/return-materials",
                nombreModulo: "solicitudMaterial",
              },
              {
                label: "menuitems.supplying.date-delivery-requests-transfer",
                link: "/supplying/requests-transfer/date-delivery",
                nombreModulo: "abastecimiento",
              },
              {
  
                label: "menuitems.supplying.formMaterials.text",
                link: "/supplying/form/materials",
                nombreModulo: "abastecimiento",
              }, 
              {
          
                label: "menuitems.supplying.formSuppliers.text",
                link: "/supplying/form/suppliers",
                nombreModulo: "abastecimiento",
              }, 
            ]
          },   
        ]
      },
      {
        label: "menuitems.supplying.cd-module",
        nombreModulo: "abastecimiento",
        subItems: [          
          {
            label: "menuitems.supplying.transfer-requests",
            link: "/supplying/cd/requests-transfer",
            nombreModulo: "abastecimiento",
          }
        ],
      },
      {
        label: "menuitems.supplying.purchase-module",
        nombreModulo: "abastecimiento",
        subItems: [          
          {
            label: "menuitems.supplying.purchase-requests",
            link: "/supplying/purchase-requests",
            nombreModulo: "abastecimiento",
          },
          {
            label: "menuitems.supplying.formMaterials.materials",
            link: "/supplying/purchase-module/materials",
            nombreModulo: "abastecimiento",
          },
          {
            label: "menuitems.supplying.formSuppliers.suppliers",
            link: "/supplying/purchase-module/suppliers",
            nombreModulo: "abastecimiento",
          },
        ],
      },
      {
        label: "menuitems.bodegamovil.text",
        icon: "bx bx-detail",
        nombreModulo: "abastecimiento",
        subItems: [
          {
      
            label: "menuitems.bodegamovil.list.solicitudesBodegaMovil",
            link: "/supplying/mobile-warehouses/requests",
            nombreModulo: "abastecimiento",
              },
          {
      
            label: "menuitems.bodegamovil.list.transferenciaMaterial",
            link: "/supplying/mobile-warehouses/material-transfers",
            nombreModulo: "abastecimiento",
              },
          {
      
            label: "menuitems.bodegamovil.list.reporteStock",
            link: "/supplying/mobile-warehouses/stock",
            nombreModulo: "abastecimiento",
              },
        ],
      },
      {
        label: "menuitems.supplying.reports.text",
        icon: "bx bx-detail",
        nombreModulo: "abastecimiento",
        subItems: [
          {
            label: "menuitems.supplying.reports.user_reports",
            nombreModulo: "abastecimiento",
            subItems: [
              {
                label: "menuitems.supplying.reports.stock",
                link: "/supplying/reports/stock",
                nombreModulo: "abastecimiento",
                parentId: 122,
                enviroment: 'production'
              },
              {
          
                label: "menuitems.supplying.reports.products",
                link: "/supplying/reports/products",
                nombreModulo: "abastecimiento",
              },
            ]
          },
          {
            label: "menuitems.supplying.text",
            nombreModulo: "abastecimiento",
            subItems: [
              {
                label: "menuitems.dashboard.text",
                link: "/supplying/dashboard",
                nombreModulo: "abastecimiento",
              },    
            ]
          },
        ],
      },
    ],
  },
  {
    label: "menuitems.smartsheet.index",
    icon: "mdi-marker-check",
    nombreModulo: "nested_webpage_c_i",
    subItems: [
      {
        label: "menuitems.smartsheet.general",
        link: "/smartsheet/general",
        nombreModulo: "nested_webpage_c_i",
      },
      {
        label: "menuitems.smartsheet.residential",
        link: "/smartsheet/residential",
        nombreModulo: "nested_webpage_residencial",
      },
      {
        label: "menuitems.smartsheet.industrial_commercial",
        link: "/smartsheet/commercial-industrial",
        nombreModulo: "nested_webpage_company",
      },
      {
        label: "menuitems.smartsheet.pmgd",
        link: "/smartsheet/pmgd",
        nombreModulo: "nested_webpage_pmgd",
      },
    ],
  },
  // {
  //   id: 127,
  //   label: "menuitems.tracking.list",
  //   icon: "mdi-account",
  //   link: "/time-sheet",
  //   nombreModulo: "traking_hh",
  // },
  {
    label: "menuitems.talanaSap.text",
    icon: "bx bx-analyse",
    link: "/data/talana-sap",
    nombreModulo: "talana-sap",
  },
  {
    label: "menuitems.tutorial.text",
    icon: "bx bx-help-circle",
    link: "/tutoriales",
    nombreModulo: "tutorial",
  },
  {
    label: "menuitems.configuration.text",
    icon: "bx bx-cog",
    link: "/configuraciones",
    nombreModulo: "configuration",
  },
  {
    label: "menuitems.manual.text",
    icon: "bx bx-file",
    link: "https://s3.amazonaws.com/proyecto.fluxsolar.cl/manual-de-usuario/plataforma-de-proyectos.pdf",
    nombreModulo: "tutorial",
    outside: true
  },

];
